import React from 'react';

const IconLogo = () => (
  <svg width="84" height="96" xmlns="http://www.w3.org/2000/svg" xmlnsSvg="http://www.w3.org/2000/svg">
 <title>Logo</title>
 <g class="layer">
  <title>Layer 1</title>
  <g id="svg_1">
   <g id="svg_2" transform="translate(11 5)">
    <polygon id="Shape" points="29,-2 -10,20 -10,65 29,88 68,66 68,21 " stroke="currentColor"/>
    <path d="m0,8l0,80l0,-80zl0,3a10,5 -15 1 1 0,40l40,37" id="svg_3" stroke="white" />
   </g>
  </g>
 </g>
</svg>
);

export default IconLogo;
